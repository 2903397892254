<template>
    <div class="summary-card pt-5 pt-lg-0">
        <!-- <div class="alignment"> -->
        <b-row v-if="isDistributorSurgeonMode" class="p-4">
            <b-col>
                <b-button
                    @click="showSubmitPreOrderPrompt = true"
                    v-if="$route.name === 'ShoppingCart'"
                    :disabled="!summary.itemsCount"
                    variant="primary"
                    class="btn-block-xs-only mt-2 mr-0 mr-sm-2"
                >
                    {{ t('preorder') }}
                </b-button>
                <b-button
                    v-if="summary.isCartSummary"
                    to="/patients"
                    variant="secondary"
                    class="btn-block-xs-only mt-2"
                >
                    {{ t('checkout_ContinueShopping') }}
                </b-button>
            </b-col>
        </b-row>
        <div
            v-else
            :class="{'border border-dark rounded p-4': isShoppingCartRoute || hasPricingPermission}"
        >
            <template v-if="!currentZone || currentZone == ZoneCodes.OUS">
                <b-row class="mb-2">
                    <b-col>
                        <h5 class="font-weight-bold">{{ t('checkout_CreditInformation') }}</h5>
                    </b-col>
                </b-row>
                <b-row class="py-3 mx-0 border-bottom">
                    <b-col xl="6" class="px-0">{{ t('checkout_Limit') }}</b-col>
                    <b-col xl="6" class="px-0 text-right">{{ summary.limit | toCurrency }}</b-col>
                </b-row>
                <b-row class="pt-3 mb-5">
                    <b-col xl="6">{{ t('checkout_Balance') }}</b-col>
                    <b-col xl="6" class="text-right">{{ summary.balance | toCurrency }}</b-col>
                </b-row>
            </template>
            <template v-if="hasPricingPermission">
                <b-row>
                    <b-col>
                        <h5 class="font-weight-bold">{{ t('checkout_OrderSummary') }}</h5>
                    </b-col>
                </b-row>
            </template>
            <b-row class="py-3 mx-0 border-bottom">
                <b-col xl="6" class="px-0">
                    {{ t('checkout_Price') }} ({{ summary.itemsCount }} items)
                </b-col>
                <b-col xl="6" class="px-0 text-right">{{ summary.orderTotal | toCurrency }}</b-col>
            </b-row>
            <b-row class="py-3 mx-0 border-bottom">
                <b-col xl="6" class="px-0">{{ t('checkout_DeliveryChange') }}</b-col>
                <b-col xl="6" class="px-0 text-right">{{
                    summary.fixedFreightCharge | toCurrency
                }}</b-col>
            </b-row>
            <b-row
                class="py-3 mx-0 border-bottom text-justify text-caption"
                v-if="!currentZone || currentZone === ZoneCodes.OUS"
            >
                {{ $t('notVatIncluded') }}
            </b-row>
            <b-row class="py-3">
                <b-col xl="6">{{ t('checkout_TotalPrice') }}</b-col>
                <b-col xl="6" class="font-weight-bold text-right">{{
                    (summary.orderTotal + summary.fixedFreightCharge) | toCurrency
                }}</b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button
                        @click="goTo"
                        v-if="toggleButtonName"
                        :disabled="!summary.itemsCount || (isShoppingCartRoute && isCartLoading)"
                        variant="primary"
                        class="btn-block-xs-only mt-2 mr-0 mr-sm-2"
                    >
                        {{ toggleButtonName }}
                    </b-button>
                    <b-button
                        v-if="summary.isCartSummary"
                        to="/patients"
                        variant="secondary"
                        class="btn-block-xs-only mt-2"
                    >
                        {{ t('checkout_ContinueShopping') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <!-- </div> -->
        <b-overlay :show="showSubmitPreOrderPrompt" no-wrap fixed>
            <template #overlay>
                <div
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                >
                    <h3>
                        <strong
                            id="form-confirm-label"
                            v-html="
                                t(hasMtoLenses ? 'submitPreorderWithMto' : 'submitPreorder', {
                                    customerName: activeCustomer.name,
                                })
                            "
                        >
                        </strong>
                    </h3>
                    <div class="d-flex center mt-3">
                        <b-button
                            variant="outline-danger"
                            size="lg"
                            class="mr-3"
                            @click="showSubmitPreOrderPrompt = false"
                        >
                            {{ t('no') }}
                        </b-button>
                        <b-button variant="outline-success" size="lg" @click="placePreOrder">{{
                            t('yes')
                        }}</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import {getCheckoutRouteNames} from '@/utilities/router';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'SummaryCard',
    data() {
        return {
            ZoneCodes,
            showSubmitPreOrderPrompt: false,
        };
    },
    computed: {
        ...mapState({
            shoppingCart: (state) => state.cart.shoppingCart,
            isCartLoading: (state) => state.cart.loading,
        }),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['currentUser', 'activeCustomer']),
        ...mapGetters('cart', ['summary', 'hasMtoLenses']),
        ...mapGetters('zone', ['currentZone']),
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        hasPricingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PRICING]: [PERMISSIONS_VALUES.ENABLED],
            });
        },
        isShoppingCartRoute() {
            return this.$route.name === 'ShoppingCart';
        },
        toggleButtonName() {
            let buttonName = '';
            let checkoutRouteNames = getCheckoutRouteNames();
            if (checkoutRouteNames.includes(this.$route.name)) {
                buttonName = this.t('checkout_EditCart');
            } else if (this.isShoppingCartRoute) {
                buttonName = this.t('checkout');
            }
            return buttonName;
        },
    },
    methods: {
        ...mapActions('cart', ['fetchItemsCount', 'fetchItems']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        goTo() {
            let routeName = '';
            let checkoutRouteNames = getCheckoutRouteNames();
            if (checkoutRouteNames.includes(this.$route.name)) {
                routeName = 'ShoppingCart';
            } else if (this.$route.name === 'ShoppingCart') {
                routeName = 'ShippingDetails';
            }
            this.$router.push({
                name: routeName,
            });
        },
        /**
         * Places the pre order
         *
         * After the order has been placed, the user is routed to the order
         * confirmation page and the item data is updated.
         */
        async placePreOrder() {
            // getting the offset from UTC to local time, getTimezoneOffset does the offset from local to UTC
            this.shoppingCart.timezoneOffset = new Date().getTimezoneOffset() * -1;

            const {success, results} = await this.blockingRequest(
                'orders/submit',
                this.shoppingCart
            );
            if (success) {
                this.showSubmitPreOrderPrompt = false;
                this.$router.push({
                    name: 'PreOrderConfirmation',
                    params: {
                        defaultOrder: results,
                        isConfirmingOrder: true,
                    },
                });
                await this.fetchItemsCount();
                await this.fetchItems();
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
